import React, { useState } from 'react';
import { ArrowsIcon } from '../Icons';
import './AboutCard.scss';

export const AboutCard = ({ title, content, IconComponent, ...props }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="aboutCard"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      <ArrowsIcon className="aboutCard__arrows" />
      <div className="aboutCard__icon">
        {IconComponent && <IconComponent hover={hover} />}
      </div>
      <div className="aboutCard__text">
        <h2 className="aboutCard__title">{title}</h2>
        <div
          className="aboutCard__content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};

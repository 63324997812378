import React from 'react';
import { useSingleFetch } from '../../services';
import { PartnerLinks } from '../Partners/PartnerLinks';
import './Footer.scss';

export const Footer = () => {
  const { sponsors } = useSingleFetch();

  return (
    <footer className="footer">
      <section className="funding">
        <h4 className="funding__title">Funded by</h4>
        <div className="funding__sponsors">
          {sponsors.map((sponsor) => {
            return (
              <div key={`sponsor-${sponsor.id}`} className="funding__sponsor">
                <a
                  className="funding__sponsorLink"
                  href={sponsor.acf.website_link}
                  target="blank"
                >
                  <img
                    className="funding__sponsorLogo"
                    src={sponsor.acf.logo}
                    alt={`Logo ${sponsor.acf.name}`}
                  />
                </a>
              </div>
            );
          })}
        </div>
      </section>
      <section className="partners">
        <PartnerLinks />
      </section>
    </footer>
  );
};

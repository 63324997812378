import { default as React, useEffect } from 'react';
import { useLocation } from 'react-router';

import { Footer, Header } from '../components';

const PageLayout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [location]);

  return (
    <>
      <Header />
      <div className="page">
        <main className="page__main">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default PageLayout;

import React from 'react';
import { scrollWithHeaderOffset } from '../../utilities';
import { ArrowsIcon } from '../Icons';
import './Hero.scss';

export const Hero = ({
  className,
  imageSrc,
  children,
  hideArrows,
}) => {
  const handleArrowClick = () => {
    scrollWithHeaderOffset('.hero + *');
  };

  return (
    <section
      className={`hero ${className ? className : ''}`}
      style={{ backgroundImage: `url("${imageSrc}")` }}
    >
      <div className="hero__text">
        {children}
        {!hideArrows && (
          <div className="hero__arrows">
            <ArrowsIcon onClick={handleArrowClick} />
          </div>
        )}
      </div>
    </section>
  );
};

import React from 'react';
import { ButtonReadMore } from '../Buttons';
import './Section.scss';

export const Section = ({
  className,
  title,
  content,
  buttonText,
  buttonLink,
  imageSrc,
  children,
  ...props
}) => {
  const buttonUrl = buttonLink?.startsWith('http') || null;
  const buttonRoute = buttonLink?.startsWith('/') || null;
  return (
    <section className={`section ${className}`} {...props}>
      <div className="section__text">
        {title && <h2 className="section__title">{title}</h2>}
        {content && (
          <p
            className="section__content"
            dangerouslySetInnerHTML={{ __html: content }}
          ></p>
        )}
      </div>
      {imageSrc && (
        <div className="section__media">
          <img src={imageSrc} alt="Section" className="section__image" />
        </div>
      )}
      <div className="section__children">{children}</div>
      {buttonText && (
        <ButtonReadMore
          buttonText={buttonText}
          buttonRoute={buttonRoute}
          buttonUrl={buttonUrl}
        />
      )}
    </section>
  );
};

import { useEffect, useState } from 'react';

export const useAsync = (asyncCallback, initialValue = null) => {
  const [value, setValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (!(asyncCallback instanceof Function)) return;
      const asyncData = await asyncCallback();
      setValue(asyncData);
      setIsLoading(false);
    })();
  }, []);

  return [value, setValue, isLoading];
};

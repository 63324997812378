import React from 'react';
import { MainNavigation } from '../../components';
import './Header.scss';
import logo from '../../assets/logos/cs21-long.jpg';
import { NavLink } from 'react-router-dom';
import * as Routes from '../../routes';

export const Header = () => {
  return (
    <header className="header">
      <div className="header__content">
        <div className="header__logo">
          <NavLink to={Routes.LANDING}>
            <img src={logo} alt="Logo CS21" />
          </NavLink>
        </div>
        <MainNavigation className="header__navigation" />
      </div>
    </header>
  );
};

import React from 'react';

import '../Icon.scss';

export const SoftSkillsIcon = ({ className, hover = false, ...props }) => {
  return (
    <div
      className={`icon icon--softSkills ${className ? className : ''}`}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80.278"
        height="86.843"
        viewBox="0 0 80.278 86.843"
        className={`iconHead ${hover ? 'iconHead--hover' : ''}`}
      >
        <path
          d="M67.895,64.186q1.3,2.032,2.6,4.073.821,1.29,1.624,2.591a1.246,1.246,0,0,1-.434,1.745q-4.518,2.682-9.045,5.351c-.787.465-1.571.937-2.364,1.4a1.256,1.256,0,0,1-1.852-.452q-1.868-2.947-3.718-5.907a5.328,5.328,0,0,1-.311-.629c-2.195.434-4.214.916-6.262,1.216a30.214,30.214,0,0,1-9.079.034c-1.473-.229-2.938-.519-4.4-.808a.49.49,0,0,0-.644.281c-1.153,2.1-2.329,4.181-3.5,6.267-.531.943-1.178,1.165-2.068.695q-5.729-3.028-11.451-6.07c-.91-.484-1.1-1.07-.615-1.935q1.747-3.136,3.521-6.257a.6.6,0,0,0-.159-.9,33.846,33.846,0,0,1-9.441-14.343c-.2-.594-.369-1.2-.568-1.846q-2.586.065-5.168.129-1.411.033-2.82.052a1.24,1.24,0,0,1-1.43-1.308Q.143,41.09,0,34.618a1.185,1.185,0,0,1,1.285-1.254c1.9-.073,3.8-.111,5.7-.158.666-.016,1.334-.038,2-.019.394.012.411-.237.5-.5.648-1.977,1.18-4,1.992-5.916a31.415,31.415,0,0,1,7.034-10.23c.377-.369.334-.618.082-1.011C17.3,13.526,16.04,11.5,14.777,9.483a1.191,1.191,0,0,1,.49-1.877Q20.9,4.269,26.528.929a1.337,1.337,0,0,1,1.911.46q1.935,3.074,3.866,6.153c.092.146.2.284.321.463a36.158,36.158,0,0,1,20-.49c1.146-2.035,2.3-4.016,3.388-6.035.91-1.7,1.338-1.835,3.036-.925,3.532,1.893,7.069,3.777,10.632,5.612A1.426,1.426,0,0,1,70.4,8.353c-1.207,2.023-2.309,4.1-3.473,6.152-.208.366-.166.566.163.86a32.988,32.988,0,0,1,8.543,11.964c.532,1.267.9,2.6,1.377,3.885a.7.7,0,0,0,.542.323c1.978-.027,3.954-.091,5.932-.14.53-.014,1.059-.014,1.589-.017a1.266,1.266,0,0,1,1.458,1.343c.052,1.6.083,3.193.121,4.79.061,2.527.111,5.054.183,7.581.039,1.388-.332,1.8-1.73,1.829-2.39.054-4.779.119-7.17.151-.4.005-.466.182-.546.495a34.71,34.71,0,0,1-4.67,10.794,37.707,37.707,0,0,1-4.5,5.474C68.128,63.932,68.039,64.031,67.895,64.186Zm-47.3-29.4c-3.119,12.184,4.775,24.481,17.115,27.461C50.3,65.283,63.284,57.868,66.313,45.3c2.942-12.209-4.773-24.444-17.47-27.374S23.515,22.68,20.592,34.781Z"
          transform="translate(0 86.843) rotate(-90)"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="106.371"
        height="53.188"
        viewBox="0 0 106.371 53.188"
        className="iconBody"
      >
        <path d="M53.185,0a53.185,53.185,0,0,1,53.185,53.185C106.1,52.932.2,53.216,0,53.185A53.185,53.185,0,0,1,53.185,0Z" />
      </svg>
    </div>
  );
};

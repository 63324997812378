import React from 'react';
import { useHistory } from 'react-router';
import { ButtonReadMore } from '../Buttons';
import './PartnerCard.scss';
import * as Routes from '../../routes';

export const PartnerCard = ({ partner, className }) => {
  const history = useHistory();

  const handleClick = (event) => {
    history.push(`${Routes.PARTNER}/${partner.slug}`);
  };

  return (
    <div className={`partnerCard ${className}`}>
      <div className="partnerCard__imageContainer">
        <img
          className="partnerCard__image"
          src={partner?.acf?.image?.sizes?.medium_large}
          alt={`Logo ${partner?.acf?.name}`}
        />
        <img
          className="partnerCard__logo"
          src={partner?.acf?.logo?.url}
          alt={`Logo ${partner?.acf?.name}`}
        />
      </div>
      <div className="partnerCard__contentContainer">
        <h2 className="partnerCard__title">{partner?.acf?.name}</h2>
        <p
          className="partnerCard__intro"
          dangerouslySetInnerHTML={{ __html: partner?.acf?.short_description }}
        ></p>
        <ButtonReadMore buttonText="Learn More" onClick={handleClick} />
      </div>
    </div>
  );
};

import React, { createContext, useContext } from 'react';

import { apiConfig } from '../config';

export const ApiContext = createContext();
export const useApi = () => useContext(ApiContext);

const { baseUrl: BASE_URL } = apiConfig;

export const ApiProvider = ({ children }) => {
  const getTeamBySlug = async (slug) => {
    const url = `${BASE_URL}/team?slug=${slug}`;
    const response = await fetch(url);
    const data = await response.json();
    return data?.length ? data[0] : data;
  };

  const getTeamsByEditionId = async (editionId) => {
    if (!editionId) return;
    const url = `${BASE_URL}/team?edition=${editionId}`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };

  const exports = {
    getTeamsByEditionId,
    getTeamBySlug,
  };

  return <ApiContext.Provider value={exports}>{children}</ApiContext.Provider>;
};

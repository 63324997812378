import React from 'react';

import '../Icon.scss';

export const ResearchIcon = ({ className, hover = false, ...props }) => {
  return (
    <div
      className={`icon icon--research ${className ? className : ''}`}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="90.645"
        height="90.645"
        viewBox="0 0 90.645 90.645"
        className={`iconResearch ${hover ? 'iconResearch--hover' : ''}`}
      >
        <g transform="translate(-46.941 -378.032)">
          <g transform="translate(46.941 378.032)">
            <path
              d="M137.585,417.328c-.133.959-.248,1.921-.4,2.877a36.2,36.2,0,0,1-17.614,26.242,34.718,34.718,0,0,1-21.924,4.907,36.248,36.248,0,0,1-14.508-4.63c-1.574-.889-1.848-.83-3.138.46L62.356,464.825a11.936,11.936,0,0,1-6.474,3.712,6.5,6.5,0,0,1-3.9-.349c-4.666-2.17-6.528-6.734-3.723-11.671a15.577,15.577,0,0,1,2.493-3.186c5.913-5.973,11.872-11.9,17.816-17.844,1.123-1.123,1.195-1.455.4-2.85a36.481,36.481,0,0,1-4.6-13.525,33.024,33.024,0,0,1,.188-10.481c2.488-13.019,9.839-22.243,21.894-27.681a33.571,33.571,0,0,1,11.165-2.772,5.2,5.2,0,0,0,.679-.147h4.956a7.221,7.221,0,0,0,.775.145,33.893,33.893,0,0,1,11.169,2.761c11.375,5.063,18.588,13.656,21.478,25.793.44,1.849.618,3.758.918,5.64Zm-36.76-29.682a27.135,27.135,0,1,0,27.115,27.094A27.1,27.1,0,0,0,100.826,387.646Z"
              transform="translate(-46.941 -378.032)"
            />
            <path
              d="M123.554,401.895a27.061,27.061,0,0,1,9.135,1.912,3.813,3.813,0,0,1,2.281,4.906,3.743,3.743,0,0,1-4.9,2.246,17.131,17.131,0,0,0-8.121-1.214,16.028,16.028,0,0,0-12.979,9.011,5.419,5.419,0,0,1-2.088,2.651,3.838,3.838,0,0,1-4.362-.5,3.784,3.784,0,0,1-.97-4.283A23.793,23.793,0,0,1,118.921,402.5C120.447,402.2,122.008,402.091,123.554,401.895Z"
              transform="translate(-68.042 -387.303)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

import React from 'react';

import '../Icon.scss';

export const TeamworkIcon = ({ className, hover = false, ...props }) => {
  return (
    <div
      className={`icon icon--teamwork ${className ? className : ''}`}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 96.94 96.94"
        className={`iconWorld ${hover ? 'iconWorld--hover' : ''}`}
      >
        <title>teamwork-1</title>
        <g id="Laag_2" data-name="Laag 2">
          <g id="Laag_1-2" data-name="Laag 1">
            <path d="M32.71,8.9c2.23.15,4.52-.36,6.67.23s4,2.29,5.53,4a10.65,10.65,0,0,1,2.6,3.81c.92,2.87-.66,6.05-3,8s-5.25,2.92-8.06,4a72,72,0,0,0-10.82,5.43,15.67,15.67,0,0,0-5.08,4.36c-.7,1.06-1.17,2.27-2,3.26A6.13,6.13,0,0,1,8.13,40.36c-.43-1.1-.53-2.29-.92-3.4a3.46,3.46,0,0,0-.44-1A43.89,43.89,0,0,0,5,48.47,43.46,43.46,0,0,0,48.47,91.94q2.06,0,4.05-.18c.8-2.26,2-4.74,3.4-5.66a6.47,6.47,0,0,1,4.47-.57,16,16,0,0,0,4.57.41c.9-.13,1.76-.46,2.66-.63a7.28,7.28,0,0,1,3.65.22,44.9,44.9,0,0,0,7.94-6.32,45.88,45.88,0,0,0,4.72-5.55h-.29a4.31,4.31,0,0,1-2.72-.75,3,3,0,0,1-.87-1.55c-.72-2.77.82-5.61.84-8.47a8.14,8.14,0,0,0-3.14-6.4c-2.42-1.85-5.64-2.17-8.63-2.82s-6.22-2-7.35-4.84.24-6,1.51-8.76,2.45-6.09,1-8.77a13.05,13.05,0,0,1-1.1-1.92,4.49,4.49,0,0,1-.13-2c.39-3.21,2.39-5.85,4.27-8.36s2.82-5,4.28-7.44A42.69,42.69,0,0,0,48.47,5,43.16,43.16,0,0,0,31,8.58,7.55,7.55,0,0,0,32.71,8.9ZM96.94,48.47A48.48,48.48,0,0,1,48.47,96.94,48.48,48.48,0,0,1,0,48.47,48.48,48.48,0,0,1,48.47,0,48.48,48.48,0,0,1,96.94,48.47ZM38.5,83.56c-3.46,1.66-6.35-3.35-6.74-6s.2-5.44.51-8.15.29-5.64-1.15-7.95c-1.87-3-5.81-4.43-7.24-7.68a8.19,8.19,0,0,1-.52-4,10.24,10.24,0,0,1,3.39-6.83,7.76,7.76,0,0,1,7.31-1.58c3.7,1.24,5.65,5.32,8.95,7.4a38.86,38.86,0,0,0,3.55,1.74,11.15,11.15,0,0,1,5.2,4.68,6.25,6.25,0,0,1-.44,6.71c-1.29,1.57-3.46,2.25-4.68,3.89s-1.29,4.14-2.71,5.72c-1.08,1.21-2.83,1.71-3.86,3C38,77,41.52,82.1,38.5,83.56Z" />
          </g>
        </g>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 106.92 82.05"
        className="iconPeople"
      >
        <title>teamwork-2</title>
        <g id="Laag_2" data-name="Laag 2">
          <g id="Laag_1-2" data-name="Laag 1">
            <path d="M106.92,70.52H80.06a34.68,34.68,0,0,0-8.53-12.63,27.35,27.35,0,0,0-10-5.67,30.73,30.73,0,0,1,2.76-2.84c5.93-5.38,13.49-8,21.15-6.5a22.46,22.46,0,0,1,11.45,5.69A29.48,29.48,0,0,1,106.92,70.52Z" />
            <circle
              cx="80.89"
              cy="18.02"
              r="16.52"
              stroke="#fff"
              strokeWidth="3"
            />
            <path d="M44.49,52.87a29.57,29.57,0,0,0-9.6,5.82,32,32,0,0,0-8.78,13.7H0A27.75,27.75,0,0,1,9.48,51.25c5.93-5.38,13.48-8,21.14-6.5a22.42,22.42,0,0,1,11.45,5.69C42.93,51.23,43.74,52,44.49,52.87Z" />
            <circle
              cx="26.04"
              cy="19.89"
              r="16.52"
              stroke="#fff"
              strokeWidth="3"
            />
            <path d="M79.49,82.05H27.43a28.78,28.78,0,0,1,1.65-9.66,28.24,28.24,0,0,1,7.83-11.48,26.94,26.94,0,0,1,9.5-5.69,22.48,22.48,0,0,1,11.64-.81c.53.1,1.05.21,1.57.33A22.47,22.47,0,0,1,69.5,60.1a29.58,29.58,0,0,1,10,21.95Z" />
            <circle
              cx="53.47"
              cy="29.55"
              r="16.52"
              stroke="#fff"
              strokeWidth="3"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

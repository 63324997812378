import React from 'react';
import { useModal } from '../../services/modal.service';
import { CloseIcon } from '../Icons';
import './AboutModal.scss';

export const AboutModal = ({ title, content, IconComponent }) => {
  const { closeModal } = useModal();

  return (
    <div
      className="modal aboutModal"
      onClick={(event) => event.stopPropagation()}
    >
      <div className="aboutModal__close" onClick={closeModal}>
        <CloseIcon />
      </div>
      <div className="aboutModal__icon">
        {IconComponent && <IconComponent />}
      </div>
      <div className="aboutModal__inner">
        <div
          className="aboutModal__content"
          dangerouslySetInnerHTML={{
            __html: `<h2 class="aboutModal__title">${title}</h2>${content}`,
          }}
        ></div>
      </div>
    </div>
  );
};

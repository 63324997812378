import React from 'react';
import { useSingleFetch } from '../services';
import {
  AboutCard,
  Hero,
  ResearchIcon,
  Section,
  ShowCaseList,
} from '../components';

import './AboutPage.scss';
import { aboutPageDefaults } from '../defaults';
import { objToArray } from '../utilities';
import {
  SoftSkillsIcon,
  TeamworkIcon,
  CoachingIcon,
  ProgramIcon,
} from '../components';
import { useModal } from '../services/modal.service';
import { AboutModal } from '../components/Modals/AboutModal';
import { heroConfig } from '../config';

const iconComponents = [
  SoftSkillsIcon,
  TeamworkIcon,
  CoachingIcon,
  ProgramIcon,
  ResearchIcon,
];

export const AboutPage = () => {
  const { aboutPage: aboutPageData } = useSingleFetch();

  const { setModal } = useModal();

  return (
    <div className="page aboutPage">
      <Hero
        className="aboutPage__hero"
        imageSrc={
          typeof eval(
            `aboutPageData?.acf?.about_background_image?.${heroConfig.imgSizePath}`
          ) === 'string'
            ? eval(
                `aboutPageData?.acf?.about_background_image?.${heroConfig.imgSizePath}`
              )
            : aboutPageDefaults.hero.image
        }
      >
        <h1
          className="hero__title"
          dangerouslySetInnerHTML={{
            __html: aboutPageData?.acf?.title || aboutPageDefaults.hero.title,
          }}
        ></h1>
        <div className="hero__subtitle">
          {aboutPageData?.acf?.about_subtitle ||
            aboutPageDefaults.hero.subtitle}
        </div>
      </Hero>
      <Section
        className="section--light aboutPage__section aboutPage__section--main"
        title={
          aboutPageData?.acf?.section_1?.title ||
          aboutPageDefaults.section1.title
        }
        content={
          aboutPageData?.acf?.section_1?.content ||
          aboutPageDefaults.section1.content
        }
      />
      <section className="section aboutPage__section aboutPage__section--cards">
        <h2 className="section__title">More about us</h2>
        <div className="aboutCards">
          {Array.isArray(objToArray(aboutPageData?.acf?.cards))
            ? objToArray(aboutPageData?.acf?.cards)
                ?.filter((card) => card.key.startsWith('card'))
                .map((card, i) => (
                  <AboutCard
                    key={card.key}
                    title={card.value.title}
                    content={card.value.short_description}
                    IconComponent={iconComponents[i]}
                    onClick={() =>
                      setModal(
                        <AboutModal
                          title={card.value.title}
                          content={card.value.full_description}
                          IconComponent={iconComponents[i]}
                        />
                      )
                    }
                  />
                ))
            : aboutPageDefaults.cards.map((card, i) => (
                <AboutCard
                  key={card.title}
                  title={card.title}
                  content={card.intro}
                  IconComponent={iconComponents[i]}
                />
              ))}
        </div>
      </section>
      <Section
        className="section--light aboutPage__section aboutPage__section--funding"
        title={
          aboutPageData?.acf?.section_2?.title ||
          aboutPageDefaults.fundingSection.title
        }
        content={
          aboutPageData?.acf?.section_2?.content ||
          aboutPageDefaults.fundingSection.content
        }
      />
      <ShowCaseList />
    </div>
  );
};

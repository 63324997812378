import React from 'react';
import { useSingleFetch } from '../services';
import { Hero, PartnerCard, PartnerLinks } from '../components';

import './PartnersPage.scss';
import { partnersPageDefaults } from '../defaults';
import { heroConfig } from '../config';

export const PartnersPage = () => {
  const { partnersPage } = useSingleFetch();
  const { partners } = useSingleFetch();

  return (
    <div className="page partnersPage">
      <Hero
        className="partnersPage__hero"
        imageSrc={
          typeof eval(
            `partnersPage?.acf?.partners_background_image?.${heroConfig.imgSizePath}`
          ) === 'string'
            ? eval(
                `partnersPage?.acf?.partners_background_image?.${heroConfig.imgSizePath}`
              )
            : partnersPageDefaults.hero.image
        }
      >
        <h1
          className="hero__title"
          dangerouslySetInnerHTML={{
            __html: partnersPage?.acf?.title || partnersPageDefaults.hero.title,
          }}
        ></h1>
        <div className="hero__subtitle">
          {partnersPage?.acf?.partners_subtitle ||
            partnersPageDefaults.hero.subtitle}
        </div>
      </Hero>
      <PartnerLinks toDetail={true} />
      <section className="partnersPage__partnerCards">
        {partners &&
          partners.map((partner, i) => (
            <PartnerCard
              key={`partnerCard-${partner.id}`}
              partner={partner}
              className={`${
                i % 2 ? 'partnerCard--dark' : 'partnerCard--normal'
              }`}
            />
          ))}
      </section>
    </div>
  );
};

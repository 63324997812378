import React from 'react';
import { ArrowsIcon } from '../Icons';
import slugify from 'slugify';
import * as Routes from '../../routes';
import { useHistory } from 'react-router';
import { slugConfig } from '../../config';

import './TeamCard.scss';

export const TeamCard = ({ team }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`${Routes.TEAM}/${slugify(team?.acf?.team_name, slugConfig)}`);
  };

  return (
    <div className="teamCard">
      <div className="teamCard__image">
        <img src={team?.acf?.team_image} alt="team" />
      </div>
      <div className="teamCard__infoBox" onClick={handleClick}>
        <div className="teamCard__infoBoxTop">
          <div className="teamCard__teamName">{team?.acf?.team_name}</div>
          <ArrowsIcon className="teamCard__icon" />
        </div>
        <div className="teamCard__projectName">
          {team?.acf?.project?.project_name}
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import icon from '../../assets/icons/person.svg';

import './Icon.scss';

export const PersonIcon = ({ className, ...props }) => {
  return (
    <div className={`icon ${className ? className : ''}`} {...props}>
      <img src={icon} alt="Person icon" />
    </div>
  );
};

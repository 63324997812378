import React from 'react';

import '../Icon.scss';

export const ProgramIcon = ({ className, hover = false, ...props }) => {
  return (
    <div
      className={`icon icon--program ${className ? className : ''}`}
      {...props}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.23 115.5">
        <title>program</title>
        <g id="Laag_2" data-name="Laag 2">
          <g id="Laag_1-2" data-name="Laag 1">
            <circle cx="49.63" cy="5.57" r="5.57" />
            <path d="M77.64,10.76h-57A20.62,20.62,0,0,0,0,31.35V94.91A20.62,20.62,0,0,0,20.59,115.5H77.64A20.62,20.62,0,0,0,98.23,94.91V31.35A20.62,20.62,0,0,0,77.64,10.76ZM95.23,94.91A17.61,17.61,0,0,1,77.64,112.5h-57A17.61,17.61,0,0,1,3,94.91V31.35A17.61,17.61,0,0,1,20.59,13.76H77.64A17.61,17.61,0,0,1,95.23,31.35Z" />
            <path d="M34.62,5.57H64.56a6.3,6.3,0,0,1,6.31,6.31h0a6.3,6.3,0,0,1-6.31,6.31H34.62a6.31,6.31,0,0,1-6.32-6.31h0A6.31,6.31,0,0,1,34.62,5.57Z" />
            <rect x="12.34" y="33.33" width="73.78" height="2.1" />
            <rect x="12.22" y="45.28" width="73.78" height="2.1" />
            <rect x="12.11" y="57.23" width="73.78" height="2.1" />
            <rect x="11.99" y="69.18" width="73.78" height="2.1" />
            <rect x="11.88" y="81.13" width="73.78" height="2.1" />
            <rect x="11.76" y="93.07" width="73.78" height="2.1" />
          </g>
        </g>
      </svg>
    </div>
  );
};

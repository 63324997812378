import React from 'react';
import icon from '../../assets/icons/close.svg';

import './Icon.scss';

export const CloseIcon = ({ className, ...props }) => {
  return (
    <div className={`icon ${className ? className : ''}`} {...props}>
      <img src={icon} alt="Close icon" />
    </div>
  );
};

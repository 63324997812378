import React from 'react';
import { useHistory } from 'react-router';
import { useSingleFetch } from '../../services';
import './PartnerLinks.scss';
import * as Routes from '../../routes';

export const PartnerLinks = ({ toDetail = false }) => {
  const { partners } = useSingleFetch();
  const history = useHistory();

  const handleClick = (event, partner) => {
    if (!toDetail) return;
    event.preventDefault();
    history.push(`${Routes.PARTNER}/${partner.slug}`);
  };

  return (
    <div className="partnerLinks">
      <h4 className="partnerLinks__title">Partners</h4>
      <div className="partnerLinks__list">
        {partners &&
          partners.map((partner) => {
            return (
              <div key={`partner-${partner?.id}`} className="partnerLink">
                <a
                  className="partner__link"
                  href={partner?.acf?.website_link || ''}
                  target="blank"
                  onClick={(e) => handleClick(e, partner)}
                >
                  <img
                    className="partner__logo"
                    src={partner?.acf?.logo?.url}
                    alt={`Logo ${partner?.acf?.name}`}
                  />
                </a>
              </div>
            );
          })}
      </div>
    </div>
  );
};

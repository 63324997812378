import React, { useEffect, useState } from 'react';
import { useSingleFetch } from '../services';
import { ButtonReadMore, Hero, Section, ShowCaseList } from '../components';
import { homePageDefaults } from '../defaults';
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa';
import * as Routes from '../routes';

import './HomePage.scss';
import { heroConfig } from '../config';
import { scrollWithHeaderOffset } from '../utilities';

const socials = {
  facebook: <FaFacebookF />,
  instagram: <FaInstagram />,
  youtube: <FaYoutube />,
  linkedin: <FaLinkedin />,
  twitter: <FaTwitter />,
};

export const HomePage = () => {
  const { homePage: homePageData } = useSingleFetch();
  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    if (!homePageData?.acf?.social_links) return;
    let newSocialLinks = Object.keys(homePageData?.acf?.social_links).map(
      (key) => ({
        platform: key,
        link: homePageData?.acf?.social_links[key],
      })
    );
    newSocialLinks = newSocialLinks.filter(
      (linkObj) => !!linkObj.link && linkObj.link !== ''
    );
    setSocialLinks(newSocialLinks);
  }, [homePageData]);

  return (
    <div className="page homePage">
      <Hero
        className="homePage__hero"
        imageSrc={
          typeof eval(
            `homePageData?.acf?.home_background_image?.${heroConfig.imgSizePath}`
          ) === 'string'
            ? eval(
                `homePageData?.acf?.home_background_image?.${heroConfig.imgSizePath}`
              )
            : homePageDefaults.hero.image
        }
        hideArrows
      >
        <div className="homePage__heroLine"></div>
        <div className="homePage__heroContent">
          <h1 className="homePage__title">
            <span className="title__creative">Creative</span>
            <span className="title__skills">Skills</span>
            <span className="title__21">21</span>
          </h1>
          <div className="hero__subtitle">
            {homePageData?.acf?.home_subtitle ||
              homePageDefaults?.hero?.subtitle ||
              ''}
          </div>
          <ButtonReadMore
            className="homePage__heroButton"
            buttonText="Our projects"
            onClick={() => scrollWithHeaderOffset('#section1')}
          />
          {socialLinks?.length ? (
            <div className="homePage__social">
              {socialLinks.map((linkObj, index) => (
                <a
                  key={`socialLink-${index}`}
                  target="_blank"
                  rel="noreferrer"
                  href={linkObj.link}
                >
                  {socials[linkObj.platform]}
                </a>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </Hero>
      <Section
        className="homePage__section homePage__section--main"
        id="section1"
        title={
          homePageData?.acf?.section_1?.title || homePageDefaults.section1.title
        }
        content={
          homePageData?.acf?.section_1?.content ||
          homePageDefaults.section1.content
        }
        imageSrc={homePageData?.acf?.section_1?.image?.sizes?.large}
      >
        <ButtonReadMore
          buttonText="Learn more"
          buttonUrl={Routes.ABOUT}
          samePage
        />
      </Section>
      <ShowCaseList projects={homePageData?.acf?.showcase_projects} />
    </div>
  );
};

import React from 'react';

import '../Icon.scss';

export const CoachingIcon = ({ className, hover = false, ...props }) => {
  return (
    <div
      className={`icon icon--coaching ${className ? className : ''}`}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160.443"
        height="160.372"
        viewBox="0 0 160.443 160.372"
        className={`iconWrench ${hover ? 'iconWrench--hover' : ''}`}
      >
        <g transform="matrix(0.966, 0.259, -0.259, 0.966, 33.885, 0)">
          <g transform="matrix(0.998, -0.07, 0.07, 0.998, -74.625, -71.637)">
            <g transform="translate(68.848 85.211)">
              <path
                d="M176.351,144.652a31.825,31.825,0,0,1-13.225,4.576,46.054,46.054,0,0,1-16.048-.74,4.117,4.117,0,0,0-1.558-.178c-1.182.177-1.919,1.294-2.83,1.948A34.067,34.067,0,0,0,139.415,153c-2.257,2.095-4.381,4.325-6.558,6.5l-13.169,13.159q-13.171,13.158-26.343,26.313L87.811,204.5a11.034,11.034,0,0,1-18.945-8.367,10.384,10.384,0,0,1,3.086-6.962q18.793-18.8,37.609-37.579c3.374-3.371,6.806-6.685,10.169-10.069,3.069-3.088,7.321-6.464,9.422-10.325a3.358,3.358,0,0,0,.484-1.713c-.046-1.1-.764-2.2-1.073-3.248a36.2,36.2,0,0,1-.956-3.87,29.809,29.809,0,0,1-.349-7.821A32.456,32.456,0,0,1,166.58,86.065l-8.622,8.6c-1.881,1.876-3.81,3.707-5.631,5.639-4.8,5.087-6.265,11.065-3.966,17.648s7.155,10.407,14.1,11.307a16.349,16.349,0,0,0,14.116-4.875c4.541-4.466,9.021-8.994,13.529-13.493.2-.2.422-.389.668-.614A31.956,31.956,0,0,1,176.351,144.652Z"
                transform="translate(-68.848 -85.211)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

import React from 'react';
import icon from '../../assets/icons/arrows.svg';

import './Icon.scss';

export const ArrowsIcon = ({ className, ...props }) => {
  return (
    <div
      className={`icon icon--arrows ${className ? className : ''}`}
      {...props}
    >
      <img src={icon} alt="Arrows icon" />
    </div>
  );
};

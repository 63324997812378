import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSingleFetch } from '../../services/singleFetch.service';
import slugify from 'slugify';
import './MainNavigation.scss';
import * as Routes from '../../routes';
import { slugConfig } from '../../config';
import { MenuIcon } from '../Icons';
import { useWindowSize } from '../../hooks';

export const MainNavigation = ({ className }) => {
  const { menu, editions } = useSingleFetch();
  const [isOnEdition, setIsOnEdition] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { isMobile } = useWindowSize();

  useEffect(() => {
    setIsOpen(false);
  }, [isMobile]);

  useEffect(() => {
    setIsOnEdition(
      history.location.pathname.startsWith(Routes.EDITION) ||
        history.location.pathname.startsWith(Routes.TEAM)
    );

    setIsOpen(false);
  }, [history.location]);

  useEffect(() => {
    const htmlElement = document.querySelector('html');
    if (isOpen) {
      htmlElement.style.overflow = 'hidden';
      return;
    }
    htmlElement.style.overflow = 'initial';
  }, [isOpen]);

  return (
    <div className={`navigation ${className ? className : ''}`}>
      <div
        className={`navigation__content ${
          isOpen ? '' : 'navigation__content--closed'
        }`}
      >
        {menu.map((link, i) => {
          const realLink = link?.route || `/${slugify(link.title, slugConfig)}`;
          return (
            <NavLink
              key={`main-nav-${
                slugify(`${link?.ID || link?.id || i}`) ||
                `${Date.now() + Math.random()}`
              }`}
              to={realLink === '/home' ? '/' : realLink}
              className="navigation__link"
              exact
            >
              {link?.title}
            </NavLink>
          );
        })}
        <div
          className={`navigation__link navigation__link--group ${
            isOnEdition && !isMobile ? 'active' : ''
          }`}
        >
          <div className="navigation__linkTitle">Editions</div>
          <div className="navigation__subLinks">
            {editions?.length ? (
              editions.map((edition) => (
                <div
                  className="navigation__subLink"
                  key={`main-nav-${edition?.slug}`}
                >
                  <NavLink to={`${Routes?.EDITION}/${edition?.slug}`}>
                    {edition?.acf?.name}
                  </NavLink>
                </div>
              ))
            ) : (
              <div className="navigation__noSubLinks">
                No Editions Available
              </div>
            )}
          </div>
        </div>
        <NavLink
          key={`main-nav-documents`}
          to={Routes.DOCUMENTS}
          className="navigation__link"
          exact
        >
          Documents
        </NavLink>
      </div>
      <MenuIcon
        className={`navigation__menuIcon ${
          isOpen ? 'navigation__menuIcon--opened' : ''
        }`}
        onClick={() => setIsOpen((old) => !old)}
      />
    </div>
  );
};

import React, { createContext, useContext } from 'react';

import { apiConfig } from '../config';
import { defaultSponsors, links } from '../defaults';
import { useAsync } from '../hooks';
import { shuffleArray } from '../utilities';

export const SingleFetchContext = createContext();
export const useSingleFetch = () => useContext(SingleFetchContext);

const { baseUrl: BASE_URL } = apiConfig;

export const SingleFetchProvider = ({ children }) => {
  const [homePage] = useAsync(getHomePage, {});
  const [aboutPage] = useAsync(getAboutPage, {});
  const [partnersPage] = useAsync(getPartnersPage, {});
  const [notFoundPage] = useAsync(getNotFoundPage, {});
  const [partners] = useAsync(getPartners, null);
  const [sponsors] = useAsync(getSponsors, defaultSponsors);
  const [editions] = useAsync(getEditions, []);
  const [documents] = useAsync(getDocuments, []);
  const [menu] = useAsync(getMenu, links);

  async function getHomePage() {
    const url = `${BASE_URL}/front-page`;
    const response = await fetch(url);
    return response.json();
  }

  async function getAboutPage() {
    const url = `${BASE_URL}/pages?slug=about`;
    const response = await fetch(url);
    const data = await response.json();
    return data[0];
  }

  async function getPartnersPage() {
    const url = `${BASE_URL}/pages?slug=partners`;
    const response = await fetch(url);
    const data = await response.json();
    return data[0];
  }

  async function getNotFoundPage() {
    const url = `${BASE_URL}/pages?slug=not-found`;
    const response = await fetch(url);
    const data = await response.json();
    return data[0];
  }

  async function getSponsors() {
    let url = `${BASE_URL}/sponsor`;
    const response = await fetch(url);
    const data = await response.json();
    return Array.isArray(data) ? data : defaultSponsors;
  }

  async function getPartners() {
    const url = `${BASE_URL}/cs_partner`;
    const response = await fetch(url);
    const partnersResponse = await response.json();
    return shuffleArray(partnersResponse);
  }

  async function getEditions() {
    const url = `${BASE_URL}/cs_edition?order=asc`;
    const response = await fetch(url);
    return response.json();
  }

  async function getDocuments() {
    const url = `${BASE_URL}/document`;
    const response = await fetch(url);
    return response.json();
  }

  async function getMenu() {
    let url = `${BASE_URL}/menu`;
    const response = await fetch(url);
    const data = await response.json();
    return Array.isArray(data) ? data : links;
  }

  const exports = {
    aboutPage,
    homePage,
    partnersPage,
    notFoundPage,
    sponsors,
    partners,
    editions,
    documents,
    menu,
  };

  return (
    <SingleFetchContext.Provider value={exports}>
      {children}
    </SingleFetchContext.Provider>
  );
};

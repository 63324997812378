export const apiConfig = {
  baseUrl: process.env.REACT_APP_API_URL,
};

export const slugConfig = {
  remove: "'",
  lower: true,
};

export const heroConfig = {
  // imgSizePath: 'sizes.large'
  imgSizePath: "sizes?.['1536x1536']",
  // imgSizePath: 'url',
};

export const breakpoints = {
  mobile: 1080,
};

import React from 'react';
import { Hero, ButtonReadMore } from '../components';
import { notFoundPageDefaults } from '../defaults';
import { useSingleFetch } from '../services';
import * as Routes from '../routes';
import { heroConfig } from '../config';

import './NotFoundPage.scss';

export const NotFoundPage = () => {
  const { notFoundPage: notFoundPageData } = useSingleFetch();

  return (
    <div className="page notFoundPage">
      <Hero
        hideArrows
        imageSrc={
          typeof eval(
            `notFoundPageData?.acf?.background_image?.${heroConfig.imgSizePath}`
          ) === 'string'
            ? eval(
                `notFoundPageData?.acf?.background_image?.${heroConfig.imgSizePath}`
              )
            : notFoundPageDefaults.hero.image
        }
      >
        <h1
          dangerouslySetInnerHTML={{
            __html: `<p>${
              notFoundPageData?.acf?.title || notFoundPageDefaults.hero.title
            }</p>`,
          }}
          className="hero__title"
        ></h1>
        <div className="hero__subtitle">
          {notFoundPageData?.acf?.subtitle ||
            notFoundPageDefaults.hero.subtitle}
        </div>
        <ButtonReadMore
          className="notFoundPage__heroButton"
          buttonText="Got to homepage"
          buttonUrl={Routes.LANDING}
          samePage
        />
      </Hero>
    </div>
  );
};

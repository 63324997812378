import React from 'react';
import icon from '../../assets/icons/location.svg';

import './Icon.scss';

export const LocationIcon = ({ className, ...props }) => {
  return (
    <div className={`icon ${className ? className : ''}`} {...props}>
      <img src={icon} alt="Location icon" />
    </div>
  );
};

import React from 'react';

import './Icon.scss';

export const MenuIcon = ({ className, ...props }) => {
  return (
    <div className={`icon ${className ? className : ''}`} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="39"
        height="22"
        viewBox="0 0 39 22"
      >
        <g transform="translate(0 -0.5)">
          <rect width="39" height="7" rx="3.5" transform="translate(0 0.5)" />
          <rect width="39" height="7" rx="3.5" transform="translate(0 15.5)" />
        </g>
      </svg>
    </div>
  );
};

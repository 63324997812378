import homeHeroImage from '../assets/images/general/ghent-1.jpg';
import aboutHeroImage from '../assets/images/general/ghent-2.jpg';
import partnersHeroImage from '../assets/images/general/ghent-3.jpg';
import notFoundHeroImage from '../assets/images/general/ghent-4.jpg';
import editionHeroImage from '../assets/images/general/ghent-5.jpg';

import logoErasPlus from '../assets/logos/erasmus-plus.png';
import logoMovetia from '../assets/logos/movetia.png';

import * as Routes from '../routes';

export const links = [
  {
    ID: `${Date.now() + Math.random()}`,
    title: 'Home',
    route: Routes.LANDING,
  },
  {
    ID: `${Date.now() + Math.random()}`,
    title: 'About',
    route: Routes.ABOUT,
  },
  {
    ID: `${Date.now() + Math.random()}`,
    title: 'Partners',
    route: Routes.PARTNERS,
  },
];

export const homePageDefaults = {
  hero: {
    image: homeHeroImage,
    subtitle:
      'Creativity, problem solving, critical thinking, team collaboration and decision making.',
  },
  section1: {
    title: 'What we do',
    content:
      'Next to vocational skills, schools are expected to teach learners certain soft skills that will allow them to become good employees, entrepreneurs and citizens. In recent years, these skills have been labelled as ‘21st Century Skills’.',
  },
};

export const aboutPageDefaults = {
  hero: {
    title:
      '<p>International<br>teamwork <strong>in<br>the creative<br>industry</strong></p>',
    subtitle:
      'Next to vocational skills, schools are expected to teach learners certain soft skills that will allow them to become good employees, entrepreneurs and citizens. In recent years, these skills have been labelled as ‘21st Century Skills’.',
    image: aboutHeroImage,
  },
  section1: {
    title: 'Our main objective',
    content:
      'The overall objective if this project will be the professionalisation of the participating teachers as well as their peers at home, and the implementation of the 21st Century Skills in the strategic agendas of the schools, and, via international networks, also in other schools.',
  },
  cards: [
    {
      title: 'Soft skills',
      intro:
        'Next to vocational skills, schools are expected to teach learners certain soft skills that will allow them to become good employees, entrepreneurs and citizens.',
      content:
        'In recent years, these skills have been labelled as ‘21st Century Skills’. Many definitions and sets of skills exist under this label and related ones, including the New Skills Agenda by the EU and several sets of skills by the ‘World Economic Forum’, ‘New Vision for Education (2015)’ and ‘Future of Jobs’ (2016). What these lists have in common is a set of transversal soft skills. Including skills like creativity, problem solving, critical thinking, team collaboration and decision making.',
    },
    {
      title: 'International Teamwork',
      intro:
        'While most of these skills are transversal, different priorities can be placed depending on the domain or industry pupils and students will be working in.',
      content:
        'While most of these skills are transversal, different priorities can be placed depending on the domain or industry pupils and students will be working in. The creative industries are a sector with many special characteristics, e.g., a high emphasis on creativity and individuality, and a large proportion of entrepreneurship (freelancers and the self-employed). As such, special emphasis on those 21st Century Skills relevant for the (future) creative industries would be required for the creative industries education. As the 21st Century Skills are so practical and related to the vocational work, it would make more sense to teach them in close integration with vocational courses and assignments, but vocational teachers often lack the know-how. At the same time, these 21st Century Skills are part of global developments. Instead of integrating them individually, VET schools can exchange expertise and collaborate internationally to develop shared strategies to integrate the skills in their own education. As international collaboration and communication are often considered part of the 21st Century Skills, international collaboration can even be seen as a requirement for teaching these skills in practice. Only in international collaboration can learning opportunities be created that prepare students for a career in creative industries that more and more transcend national borders. In this project, the partners aim to define the most relevant 21st Century Skills for the creative industry education, by analysing the gap between industry needs and educational (VET) offerings.',
    },
    {
      title: 'Coaching',
      intro:
        'Subsequently they aim to bring these skills into VET education by proposing it as an integrated part of the vocational education and the creative design process.',
      content:
        'Subsequently they aim to bring these skills into VET education by proposing it as an integrated part of the vocational education and the creative design process. To do so, the project will organize training weeks where vocational teachers will receive professionalization regarding the relevant 21st Century Skills and will immediately apply these by coaching international student projects. This way, the teachers will receive knowledge and build up experience at the same time, so they can act as ‘ambassadors’ towards their colleagues at home.',
    },
    {
      title: 'Practical',
      intro:
        'In practice, eight partner schools will work together to train and coach teachers in applying the right 21st Century Skills during six training weeks.',
      content:
        'In practice, eight partner schools will work together to train and coach teachers in applying the right 21st Century Skills during six training weeks. The participating students work on idealistic creative projects and will essentially be the ‘guinea pigs’ for the participating teachers. The training and coaching LTT meetings are organized in three sets of two meetings each, while taking place in different semesters and different cities. The participating teachers and students remain the same during the first and second LTT meeting of a set, so that reflection, deepening and widening can take place not only during the two meetings but between two meetings. The professionalization of the teachers is directly applied and tested during the LTT meetings, but they will also implement it upon return in their own schools.',
    },
    {
      title: 'Research',
      intro:
        'While many overviews, frameworks and lists of 21st Century Skills exist, there is little research on the skills that matter most for (future) professionals specifically in the creative industries.',
      content:
        'Two intellectual outputs will be created in the project. While many overviews, frameworks and lists of 21st Century Skills exist, there is little research on the skills that matter most for (future) professionals specifically in the creative industries. The output report ‘21st Century Skills for the creative industries’ will combine desk research with a consultation of representatives of the creative industries in the networks of all participating partners. It will prioritize the skills to focus on. Based on the results of this research and the results of the professionalization exercises during the project lifespan, we will create a guidebook (in print and as a website) with practical examples and exercises for VET teachers within the creative industries on how to teach relevant 21st Century Skills and implement them into the learning programs and curriculums of VET schools.',
    },
  ],
  fundingSection: {
    title: 'Funding',
    content:
      'This project is possible with the help of ERASMUS+ and Movetia. ERASMUS+ subsidize all costs except the cost for the Swiss partner and the meeting in Switzerland. See also: https://www.erasmusplus.nl/ Movetia subsidize the costs for the Swiss partner and for all partners the cost of the meeting in Switzerland. See also: https://www.movetia.ch/',
  },
};

export const editionPageDefaults = {
  hero: {
    title: `<p>Explore our<br><strong>edition ’19-‘20<br>projects</strong></p>`,
    subtitle:
      'The participating students work on idealistic creative projects. Scroll down to see them all!',
    image: editionHeroImage,
  },
  sectionDescription: {
    title: (editionName) => `About ${editionName}`,
  },
  section1: {
    title: 'PROJECTS',
  },
  section2: {
    title: 'Teams',
  },
  section3: {
    title: 'PROJECT WEEKS',
  },
};

export const partnersPageDefaults = {
  hero: {
    title: '<p>Get to know <strong>all<br>our partners</strong></p>',
    subtitle:
      'Schools over the whole world participate in this projects. Want to get to know them? Keep scrolling.',
    image: partnersHeroImage,
  },
};

export const partnerPageDefaults = {
  image: partnersHeroImage,
};

export const defaultSponsors = [
  {
    id: 'sd1fs3df21s5cd',
    acf: {
      name: 'Erasmus+',
      logo: logoErasPlus,
      website_link: 'https://www.erasmusplus.nl/english',
    },
  },
  {
    id: 's6d51f6s5c16wd5',
    acf: {
      name: 'Movetia',
      logo: logoMovetia,
      website_link: 'https://www.movetia.ch/en/',
    },
  },
];

export const notFoundPageDefaults = {
  hero: {
    title: 'Not <strong>found</strong>',
    subtitle:
      "The page that you requested has not been found. It's possible that the content you’ve been looking for has been changed or removed.",
    image: notFoundHeroImage,
  },
};

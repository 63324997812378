import { breakpoints } from '../config';

export const shuffleArray = (array) => {
  if (!Array.isArray(array)) return array;
  if (!(array.length > 0)) return array;

  let localArray = [...array];
  let length = localArray.length,
    temp,
    i;

  while (length) {
    i = Math.floor(Math.random() * length--);

    temp = localArray[length];
    localArray[length] = localArray[i];
    localArray[i] = temp;
  }

  return localArray;
};

export const objToArray = (object) => {
  if (typeof object !== 'object') return;

  return Object.keys(object).map((key) => ({
    key,
    value: object[key],
  }));
};

export const convertRemToPixels = (rem) =>
  rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export const isMobileBreakpoint = () => window.innerWidth <= breakpoints.mobile;

export const scrollWithHeaderOffset = (elementSelector) => {
  const element = document.querySelector(elementSelector);
  const offset = isMobileBreakpoint() ? -70 : -80;
  const scrollPositionY =
    element.getBoundingClientRect().top + window.pageYOffset + offset;
  window.scrollTo({
    top: scrollPositionY,
    left: 0,
    behavior: 'smooth',
  });
};

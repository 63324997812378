export const LANDING = '/';
export const HOME = '/home';
export const ABOUT = '/about';
export const EDITIONS = '/editions';
export const EDITION = '/edition';
export const TEAM = '/team';
export const PARTNERS = '/partners';
export const PARTNER = '/partner';
export const DOCUMENTS = '/documents';
export const NOT_FOUND = '/not-found';

import React from 'react';
import { useSingleFetch } from '../services';

import './DocumentsPage.scss';

export const DocumentsPage = () => {
  const { documents } = useSingleFetch();

  return (
    <div className="page documentsPage">
      {documents?.length ? (
        <div className="documentsPage__documentList">
          {documents.map((doc, i) => {
            return (
              <div
                key={`document-${i}`}
                className="documentsPage__document document"
                onClick={(e) => {
                  e.preventDefault();
                  const redirect = doc?.acf?.file?.url || doc?.acf?.link;
                  if (redirect) window.open(redirect, '_blank');
                }}
              >
                <h2 className="document__name">{doc?.acf?.name}</h2>
                <p className="document__description">{doc?.acf?.description}</p>
                <img
                  src={doc?.acf?.thumbnail?.sizes?.large}
                  alt=""
                  className="document__thumbnail"
                />
              </div>
            );
          })}
        </div>
      ) : (
        <h1 className="documentsPage__title">No documents</h1>
      )}
    </div>
  );
};

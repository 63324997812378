import './App.scss';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import * as Routes from './routes';
import {
  AboutPage,
  HomePage,
  PartnerPage,
  PartnersPage,
  EditionPage,
  TeamPage,
  NotFoundPage,
  DocumentsPage,
} from './pages';
import { ApiProvider } from './services';
import { RouteWithLayout } from './utilities';
import PageLayout from './layouts/PageLayout';
import { SingleFetchProvider } from './services/singleFetch.service';
import { ModalProvider } from './services/modal.service';

const Stores = ({ children }) => (
  <ApiProvider>
    <ModalProvider>
      <SingleFetchProvider>{children}</SingleFetchProvider>
    </ModalProvider>
  </ApiProvider>
);

function App() {
  return (
    <div className="app">
      <Stores>
        <Router basename="/">
          <Switch>
            <RouteWithLayout
              exact
              path={Routes.LANDING}
              component={HomePage}
              layout={PageLayout}
            />
            <Redirect from={Routes.HOME} to={Routes.LANDING} />
            <RouteWithLayout
              exact
              path={Routes.ABOUT}
              component={AboutPage}
              layout={PageLayout}
            />
            <Route
              exact
              path={`${Routes.PARTNER}/:slug`}
              component={PartnerPage}
            />
            <Redirect from={Routes.PARTNER} to={Routes.PARTNERS} />
            <RouteWithLayout
              exact
              path={Routes.PARTNERS}
              component={PartnersPage}
              layout={PageLayout}
            />
            <RouteWithLayout
              exact
              path={`${Routes.EDITION}/:slug`}
              component={EditionPage}
              layout={PageLayout}
            />
            <RouteWithLayout
              exact
              path={`${Routes.TEAM}/:slug`}
              component={TeamPage}
              layout={PageLayout}
            />

            <RouteWithLayout
              exact
              path={Routes.DOCUMENTS}
              component={DocumentsPage}
              layout={PageLayout}
            />

            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </Stores>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useSingleFetch } from '../services';
import { CloseIcon, LinkIcon, LocationIcon, PersonIcon } from '../components';

import './PartnerPage.scss';
import { partnerPageDefaults } from '../defaults';
import { Redirect, useHistory, useParams } from 'react-router';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';

import locationIconMarker from '../assets/icons/location.svg';
import { heroConfig } from '../config';
import * as Routes from '../routes';

const markerIcon = new L.Icon({
  iconUrl: locationIconMarker,
  iconAnchor: new L.Point(17.5, 50),
  className: 'leaflet-marker-icon',
});

export const PartnerPage = () => {
  const { partners } = useSingleFetch();
  const { slug } = useParams();
  const [partner, setPartner] = useState('loading');
  const [location, setLocation] = useState(null);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    if (!partner) return;
    (async () => {
      const query = `country=${encodeURI(
        partner?.acf?.address?.country
      )}&city=${encodeURI(partner?.acf?.address?.city)}&street=${encodeURI(
        `${partner?.acf?.address?.house_number} ${partner?.acf?.address?.street}`
      )}&format=json`;
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?${query}`
      );
      const data = await response.json();
      setLocation(data[0]);
    })();
  }, [partner]);

  useEffect(() => {
    if (!partners?.length) return;

    const foundPartner = partners.find((partner) => partner.slug === slug);

    setPartner(foundPartner);
  }, [partners, slug]);

  return (
    <div className="page partnerPage">
      <div className="partnerPage__side">
        {partner?.acf?.image?.url && (
          <img
            src={
              typeof eval(`partner?.acf?.image?.${heroConfig.imgSizePath}`) ===
              'string'
                ? eval(`partner?.acf?.image?.${heroConfig.imgSizePath}`)
                : partnerPageDefaults.image
            }
            alt="Partner"
          />
        )}
      </div>
      <div className="partnerPage__content">
        {partner ? (
          <>
            <CloseIcon
              className="partnerPage__close"
              onClick={() => history.push(Routes.PARTNERS)}
            />
            <h1 className="partnerPage__title">{partner?.acf?.name}</h1>
            <div
              className="partnerPage__description"
              dangerouslySetInnerHTML={{
                __html: partner?.acf?.full_description,
              }}
            ></div>
            <div className="partnerPage__meta">
              <div className="partnerPage__contactPerson">
                <PersonIcon />
                <div className="contactLines">
                  <div className="contactLines__name">
                    {partner?.acf?.contact_person?.name}
                    <div className="contactLines__title">
                      {partner?.acf?.contact_person?.title &&
                        `(${partner?.acf?.contact_person?.title})`}
                    </div>
                  </div>
                  <div className="contactLines__email">
                    {partner?.acf?.contact_person?.email}
                  </div>
                </div>
              </div>
              <div className="partnerPage__link">
                <LinkIcon />
                <a href={partner?.acf?.website_link || ''}>
                  {partner?.acf?.website_link}
                </a>
              </div>
              <div className="partnerPage__location">
                <LocationIcon />{' '}
                <div className="addressLines">
                  <div className="addressLines__address">
                    {partner?.acf?.address?.street}{' '}
                    {partner?.acf?.address?.house_number}
                  </div>
                  <div className="addressLines__general">
                    {partner?.acf?.address?.postal_code}{' '}
                    {partner?.acf?.address?.city},{' '}
                    {partner?.acf?.address?.country}
                  </div>
                </div>
              </div>
            </div>
            {location && (
              <MapContainer
                center={[location.lat, location.lon]}
                zoom={5}
                scrollWheelZoom={true}
              >
                <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png" />
                <Marker
                  position={[location.lat, location.lon]}
                  icon={markerIcon}
                />
              </MapContainer>
            )}
          </>
        ) : partners?.length && partner !== 'loading' ? (
          <Redirect to={Routes.NOT_FOUND} />
        ) : null}
      </div>
    </div>
  );
};

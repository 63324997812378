import React, { createContext, useContext, useEffect, useState } from 'react';
import '../components/Modals/ModalContainer.scss';

export const ModalContext = createContext();
export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [currentModal, setCurrentModal] = useState();

  const setModal = (modalComponent) => setCurrentModal(modalComponent);

  const closeModal = () => setCurrentModal(null);

  useEffect(() => {
    document.body.style.overflow = currentModal ? 'hidden' : 'initial';
  }, [currentModal]);

  const exports = {
    setModal,
    closeModal,
  };

  const handleExitModal = (event) => {
    event.stopPropagation();
    closeModal();
  };

  return (
    <ModalContext.Provider value={exports}>
      <div
        className={`modalContainer ${
          currentModal ? '' : 'modalContainer--closed'
        }`}
        onClick={handleExitModal}
      >
        {currentModal}
      </div>
      {children}
    </ModalContext.Provider>
  );
};

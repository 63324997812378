import React from 'react';
import { useHistory } from 'react-router';
import { ArrowsIcon } from '../Icons';
import './Button.scss';

export const ButtonReadMore = ({
  buttonText,
  buttonRoute,
  buttonUrl,
  onClick,
  className,
  samePage,
  ...props
}) => {
  const history = useHistory();

  const handleClick = (event) => {
    if (onClick instanceof Function) onClick(event);
    if (buttonUrl) {
      if (samePage) {
        history.push(buttonUrl);
        return;
      }
      window.open(buttonUrl, '_blank');
      return;
    }
    if (buttonRoute) history.push();
  };
  return (
    <div
      className={`button button--readmore ${className ? className : ''}`}
      onClick={handleClick}
      {...props}
    >
      {buttonText}
      <ArrowsIcon className="arrows" />
    </div>
  );
};

import React from 'react';
import icon from '../../assets/icons/link.svg';

import './Icon.scss';

export const LinkIcon = ({ className, ...props }) => {
  return (
    <div className={`icon ${className ? className : ''}`} {...props}>
      <img src={icon} alt="Link icon" />
    </div>
  );
};

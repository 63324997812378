import React from 'react';
import { useHistory } from 'react-router';
import slugify from 'slugify';
import { ArrowsIcon } from '../Icons';
import * as Routes from '../../routes';
import { slugConfig } from '../../config';

import './ShowCaseElement.scss';

export const ShowCaseElement = ({ team, triggerOnWholeElement }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`${Routes.TEAM}/${slugify(team?.acf?.team_name, slugConfig)}`);
  };

  return (
    <div
      className="showCaseElement"
      onClick={() => {
        if (triggerOnWholeElement) handleClick();
      }}
    >
      <div className="showCaseElement__image">
        <img
          src={
            team?.acf?.project?.project_image?.sizes?.large ||
            team?.acf?.project_image ||
            team?.acf?.project?.project_image_2?.sizes?.large ||
            team?.acf?.project_image_2 ||
            team?.acf?.team_image
          }
          alt="team"
        />
      </div>
      <div className="showCaseElement__infoBox" onClick={handleClick}>
        <div className="showCaseElement__edition">
          {team?.acf?.edition?.acf?.period}
        </div>
        <ArrowsIcon className="showCaseElement__icon" />
        <div className="showCaseElement__name">
          {team?.acf?.project?.project_name}
        </div>
        <div className="showCaseElement__description">
          {team?.acf?.project?.project_short_description}
        </div>
      </div>
    </div>
  );
};

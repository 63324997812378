import React, { useEffect, useReducer, useState } from 'react';
import { useApi, useSingleFetch } from '../services';
import {
  ArrowsIcon,
  Hero,
  Section,
  ShowCaseElement,
  TeamCard,
} from '../components';

import './EditionPage.scss';
import { editionPageDefaults } from '../defaults';
import { Redirect, useParams } from 'react-router';
import { useAsync } from '../hooks';
import { heroConfig } from '../config';
import * as Routes from '../routes';

const showcaseReducer = (state, action) => {
  let newIndex = state.currentIndex;

  switch (action.type) {
    case 'reset':
      newIndex = 0;
      break;
    case 'next':
      newIndex = (newIndex + 1) % action.teams?.length;
      break;
    case 'prev':
      newIndex = (action.teams?.length + (newIndex - 1)) % action.teams?.length;
      break;
    default:
      throw new Error();
  }

  return {
    currentTeam: action.teams?.length ? action.teams[newIndex] : null,
    currentIndex: newIndex,
  };
};

const findEdition = (editions, slug) => {
  return editions?.length ? editions.find((ed) => ed.slug === slug) : null;
};

export const EditionPage = () => {
  const { editions } = useSingleFetch();
  const { getTeamsByEditionId } = useApi();
  const { slug } = useParams();
  const [edition, setEdition] = useState(findEdition(editions, slug));

  const [areTeamsLoading, setAreTeamsLoading] = useState(true);
  const [teams, setTeams] = useState([]);

  const [teamsWithProjects, setTeamsWithProjects] = useAsync([]);

  const [currentShowcase, showcaseDispatch] = useReducer(showcaseReducer, {
    currentTeam: teamsWithProjects?.length ? teamsWithProjects[0] : null,
    currentIndex: 0,
  });

  useEffect(() => {
    const foundEdition = findEdition(editions, slug);
    if (editions?.length && !foundEdition) {
      setAreTeamsLoading(false);
      return;
    }
    setEdition(foundEdition);
  }, [editions, slug]);

  useEffect(() => {
    (async () => {
      if (!edition) return;
      const result = await getTeamsByEditionId(edition?.id || edition?.ID);
      setTeams(result);
      setAreTeamsLoading(false);
    })();
  }, [edition, getTeamsByEditionId, setTeams]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!teams?.length) {
      setTeamsWithProjects([]);
      return;
    }

    setTeamsWithProjects(
      teams.filter(
        (team) =>
          team?.acf?.project_image ||
          team?.acf?.project?.project_image ||
          team?.acf?.project?.project_name
      )
    );
  }, [teams, setTeamsWithProjects]);

  useEffect(() => {
    showcaseDispatch({ teams: teamsWithProjects, type: 'reset' });
  }, [teamsWithProjects]);

  return (
    <div className="page editionPage">
      {!areTeamsLoading && !edition ? (
        <Redirect to={Routes.NOT_FOUND} />
      ) : (
        <>
          <Hero
            className="editionPage__hero"
            imageSrc={
              typeof eval(
                `edition?.acf?.hero_image?.${heroConfig.imgSizePath}`
              ) === 'string'
                ? eval(`edition?.acf?.hero_image?.${heroConfig.imgSizePath}`)
                : editionPageDefaults.hero.image
            }
            hideArrows={!teams?.length}
          >
            <h1
              className="hero__title"
              dangerouslySetInnerHTML={{
                __html: teams?.length
                  ? edition?.acf?.hero_title || editionPageDefaults.hero.title
                  : areTeamsLoading
                  ? ''
                  : 'This edition is coming soon',
              }}
            ></h1>
            {teams?.length ? (
              <div className="hero__subtitle">
                {edition?.acf?.hero_subtitle ||
                  editionPageDefaults.hero.subtitle}
              </div>
            ) : (
              <></>
            )}
          </Hero>
          {edition?.acf?.description ? (
            <Section
              className="editionPage__section editionPage__section--description"
              title={
                edition?.acf?.sectionDescription?.title ||
                editionPageDefaults.sectionDescription.title(edition?.acf?.name)
              }
              content={edition?.acf?.description}
            ></Section>
          ) : null}
          {teams?.length ? (
            <>
              <Section
                className="section--light editionPage__section editionPage__section--projects"
                title={
                  edition?.acf?.section_1?.title ||
                  editionPageDefaults.section1.title
                }
              >
                <ArrowsIcon
                  className="editionPage__projectNav editionPage__projectNav--left"
                  onClick={() =>
                    showcaseDispatch({ teams: teamsWithProjects, type: 'prev' })
                  }
                />
                <div className="editionPage__project">
                  <ShowCaseElement
                    team={currentShowcase?.currentTeam}
                    // triggerOnWholeElement
                  />
                </div>
                <ArrowsIcon
                  className="editionPage__projectNav editionPage__projectNav--right"
                  onClick={() =>
                    showcaseDispatch({ teams: teamsWithProjects, type: 'next' })
                  }
                />
              </Section>
              <Section
                className="editionPage__section editionPage__section--teams"
                title={
                  edition?.acf?.section_2?.title ||
                  editionPageDefaults.section2.title
                }
              >
                {teams?.length ? (
                  teams.map((team, i) => (
                    <TeamCard
                      key={`team-${i}-${team?.id || team?.ID}`}
                      team={team}
                    />
                  ))
                ) : (
                  <></>
                )}
              </Section>
              {(edition?.acf?.project_weeks?.week_1?.cs_location ||
                edition?.acf?.project_weeks?.week_2?.cs_location) && (
                <Section
                  className="section--light editionPage__section editionPage__section--projectWeeks"
                  title={
                    edition?.acf?.section_3?.title ||
                    editionPageDefaults.section3.title
                  }
                >
                  {edition?.acf?.project_weeks?.week_1?.cs_location && (
                    <div className="editionPage__projectWeekContainer">
                      <div className="editionPage__projectWeek">
                        <div className="editionPage__projectWeekTitle">
                          {`Week 1 - ${edition?.acf?.project_weeks?.week_1?.cs_location}`}
                        </div>
                        <div className="editionPage__projectWeekDescription">
                          {edition?.acf?.project_weeks?.week_1?.description}
                        </div>
                        {edition?.acf?.project_weeks?.week_1?.glide_app_url ? (
                          <iframe
                            className="editionPage__projectWeekIframe"
                            src={
                              edition?.acf?.project_weeks?.week_1?.glide_app_url
                            }
                            title={`${edition?.acf?.project_weeks?.week_1?.glide_app_url}`}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                  {edition?.acf?.project_weeks?.week_2?.cs_location && (
                    <div className="editionPage__projectWeekContainer">
                      <div className="editionPage__projectWeek">
                        <div className="editionPage__projectWeekTitle">
                          {`Week 2 - ${edition?.acf?.project_weeks?.week_2?.cs_location}`}
                        </div>
                        <div className="editionPage__projectWeekDescription">
                          {edition?.acf?.project_weeks?.week_2?.description}
                        </div>
                        {edition?.acf?.project_weeks?.week_1?.glide_app_url ? (
                          <iframe
                            className="editionPage__projectWeekIframe"
                            src={
                              edition?.acf?.project_weeks?.week_2?.glide_app_url
                            }
                            title={`${edition?.acf?.project_weeks?.week_2?.glide_app_url}`}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </Section>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

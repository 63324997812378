import React, { useEffect, useRef, useState } from 'react';

import './TeamPage.scss';
import { Redirect, useHistory, useParams } from 'react-router';
import { useAsync, useWindowSize } from '../hooks';
import { useApi } from '../services';
import * as Routes from '../routes';
import { slugConfig } from '../config';
import slugify from 'slugify';
import { ArrowsIcon } from '../components';

export const TeamPage = () => {
  const { slug } = useParams();
  const { getTeamBySlug, getTeamsByEditionId } = useApi();
  const [initialTeam, , isInitialTeamLoading] = useAsync(
    () => getTeamBySlug(slug),
    []
  );
  const [selectedTeam, setSelectedTeam] = useState();
  const [teams, setTeams] = useState();
  const history = useHistory();
  const menuRef = useRef();
  const menuParentRef = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    document.body.style.overflow = showMenu ? 'hidden' : 'initial';
  }, [showMenu]);

  useEffect(() => {
    if (!initialTeam) return;

    const editionId =
      initialTeam.acf?.edition?.id || initialTeam.acf?.edition?.ID;

    if (!editionId) return;

    setSelectedTeam(initialTeam);

    (async () => {
      const result = await getTeamsByEditionId(editionId);
      setTeams(result);
    })();
  }, [initialTeam, getTeamsByEditionId]);

  useEffect(() => {
    if (!selectedTeam) return;
    if (!selectedTeam?.acf?.team_name) return;
    history.push(
      `${Routes.TEAM}/${slugify(selectedTeam?.acf?.team_name, slugConfig)}`
    );
  }, [selectedTeam, history]);

  useEffect(() => {
    setShowMenu(false);
  }, [isMobile]);

  const handleClick = (event, team) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedTeam(team);
    setShowMenu(false);
  };

  const handleBackClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(
      `${Routes?.EDITION}/${slugify(
        initialTeam?.acf?.edition?.post_title,
        slugConfig
      )}`
    );
  };

  return (
    <div className="page teamPage" ref={menuParentRef}>
      {isInitialTeamLoading ? null : Array.isArray(initialTeam) && // <h1>LOADING</h1>
        !initialTeam?.length ? (
        <Redirect to={Routes.NOT_FOUND} />
      ) : (
        <>
          <div
            className={`teamPage__menu ${
              showMenu ? '' : 'teamPage__menu--hide'
            }`}
            ref={menuRef}
          >
            {initialTeam?.acf?.edition?.post_title && (
              <div className="teamPage__backButton" onClick={handleBackClick}>
                <ArrowsIcon className="teamPage__arrows" />
                Go back to overview
              </div>
            )}
            <div
              className="teamPage__navigationHeader"
              onClick={() => setShowMenu((old) => !old)}
            >
              <ArrowsIcon className="teamPage__menuToggle" />
              {initialTeam?.acf?.edition?.post_title
                ? selectedTeam?.acf?.project?.project_name ||
                  'This team has no project'
                : ''}
            </div>
            <div className="teamPage__navigation">
              {teams?.length ? (
                teams.map((team, index) => {
                  const teamId = team?.id || team?.ID;
                  const selectedTeamId = selectedTeam?.id || selectedTeam?.ID;
                  return (
                    <div
                      key={teamId}
                      className={`teamPage__menuElement ${
                        teamId && selectedTeamId && teamId === selectedTeamId
                          ? 'teamPage__menuElement--selected'
                          : ''
                      }`}
                      onClick={(event) => handleClick(event, team)}
                    >
                      {`${String(index + 1).padStart(2, '0')} `}
                      {team?.acf?.project?.project_name ||
                        `${team?.acf?.team_name} (No project)`}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="teamPage__content">
            <div
              className="teamPage__projectName"
              onClick={() => setShowMenu((old) => !old)}
            >
              <ArrowsIcon className="teamPage__menuToggle" />
              {initialTeam?.acf?.edition?.post_title
                ? selectedTeam?.acf?.project?.project_name ||
                  'This team has no project'
                : ''}
            </div>
            <div className="teamPage__teamName">
              {selectedTeam?.acf?.team_name}
            </div>
            <div className="teamPage__teamMembers">
              {`${selectedTeam?.acf?.students || ''}`
                .split(', ')
                .map((studentName) => (
                  <div key={studentName} className="teamPage__teamMember">
                    {studentName}
                  </div>
                ))}
            </div>
            {selectedTeam?.acf?.project?.project_full_description ? (
              <div className="teamPage__projectDescription">
                {selectedTeam?.acf?.project?.project_full_description}
              </div>
            ) : (
              <></>
            )}
            {selectedTeam?.acf?.project?.project_link ? (
              <a
                href={selectedTeam.acf.project.project_link}
                target="_blank"
                rel="noreferrer"
                className="teamPage__projectLink"
              >
                {selectedTeam?.acf?.project?.project_link}
              </a>
            ) : (
              <></>
            )}
            <div className="teamPage__projectMedia">
              {selectedTeam?.acf?.project?.project_image ? (
                <div className="teamPage__projectImage">
                  <img
                    src={
                      selectedTeam?.acf?.project?.project_image?.sizes?.large
                    }
                    alt="project"
                  />
                </div>
              ) : (
                <></>
              )}
              {selectedTeam?.acf?.project?.project_image_2 ? (
                <div className="teamPage__projectImage">
                  <img
                    src={
                      selectedTeam?.acf?.project?.project_image_2.sizes?.large
                    }
                    alt="project other"
                  />
                </div>
              ) : (
                <></>
              )}
              {selectedTeam?.acf?.project?.project_video.url ? (
                <div className="teamPage__projectVideo">
                  <video controls>
                    <source
                      src={selectedTeam?.acf?.project?.project_video?.url}
                      type={
                        selectedTeam?.acf?.project?.project_video?.mime_type
                      }
                    />
                  </video>
                </div>
              ) : (
                <></>
              )}
              {selectedTeam?.acf?.project?.project_video_2.url ? (
                <div className="teamPage__projectVideo">
                  <video controls>
                    <source
                      src={selectedTeam?.acf?.project?.project_video_2?.url}
                      type={
                        selectedTeam?.acf?.project?.project_video_2?.mime_type
                      }
                    />
                  </video>
                </div>
              ) : (
                <></>
              )}
              {selectedTeam?.acf?.project?.project_file?.url ||
              selectedTeam?.acf?.project?.project_file_2?.url ? (
                <div className="teamPage__projectFiles">
                  <div className="teamPage__projectFilesTitle">Files</div>
                  {selectedTeam?.acf?.project?.project_file?.url ? (
                    <div
                      className="teamPage__projectFile"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          selectedTeam?.acf?.project?.project_file?.url,
                          '_blank'
                        );
                      }}
                    >
                      {selectedTeam?.acf?.project?.project_file?.filename}
                    </div>
                  ) : (
                    <></>
                  )}
                  {selectedTeam?.acf?.project?.project_file_2?.url ? (
                    <div
                      className="teamPage__projectFile"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          selectedTeam?.acf?.project?.project_file_2?.url,
                          '_blank'
                        );
                      }}
                    >
                      {selectedTeam?.acf?.project?.project_file_2?.filename}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

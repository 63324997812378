import React from 'react';
import { shuffleArray } from '../../utilities';
import { ShowCaseElement } from './ShowCaseElement';

export const ShowCaseList = ({ projects }) => {
  return (
    <div className="showCaseList">
      {projects?.length ? (
        shuffleArray(projects).map((team, i) => {
          return <ShowCaseElement key={`showcase-${i}`} team={team} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};
